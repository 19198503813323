<template>
    <div id="contents" class="contents vmd page" v-if="pageLoaded">
        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
            <div class="page_ttl_wrap">
                <div class="channel_list_type">
                    <p>{{ templateName }}</p>
                </div>
                <div class="channel_list_num">
                    <h2><p>채널 {{ channel.no }}</p></h2>
                </div>
                <div class="page_ttl">
                    <div class="editor_area" v-if="!titleEditMode">
                        <h2 v-if="channel.name">{{channel.name}}</h2>
                        <h2 v-else>채널명을 입력 해 주세요</h2>
                        <!-- Dev : editor_btn 클릭시 -->
                        <button class="editor_btn" @click="titleEditMode = true">
                            <img src="../../../assets/img/common/vdm_search.png" alt="">
                        </button>
                    </div>
                    <!--Dev : editor_btn 클릭시 .page_ttl에 input_area로 적용-->
                    <div class="input_area inline" v-else>
                        <label></label>
                        <div class="input_box">
                            <input type="text" placeholder="채널명" v-model="editChannelName">
                        </div>
                        <div class="btn_area">
                            <button class="btn basic w_ss h_l" @click="changeChannelName">확인</button>
                        </div>
                        <small class="error_txt" style="display: none">채널명을 입력 해 주세요.</small>
                    </div>
                </div>
            </div>
        </div>
        <!-- e: 페이지 타이틀 -->
        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: item_list_sec-->
            <div class="item_list_sec">
                <!-- Dev: 상품 추가 wrap -->
                <div class="item_list_wrap none min" :class="{is_active : activeIndex === -1}"
                     @click="clickAddBtn()">
                    <div class="item_list_head min">
                        <div class="item_thumb">
                            <button>추가버튼</button>
                        </div>
                    </div>
                </div>
                <!-- Dev : 등록된 상품  -->
                <div class="registration_item_wrap" v-if="channel && channel.vmd">
                    <Container class="registration_item_area"
                               orientation="horizontal"
                               tag="div"
                               :drop-placeholder="dropPlaceholderOptions"
                               @drop="onDrop">
                        <Draggable class="item_list_wrap min" v-for="(item, i) in orderedVmd" :key="'item' + i"
                                   :class="{is_active : i === activeIndex}" @click="clickVmd(i, item)">
                            <div class="item_list_head min">
                                <div class="item_list_body">
                                    {{ item.name ? item.name : item.files && item.files[0] ? fileNameFromUrl(item.files[0]) : item.files[0]
                                    }}
                                </div>
                                <div class="delete_btn_area">
                                    <button class="delete_btn" @click="removeList(i)">삭제버튼</button>
                                </div>
                            </div>
                        </Draggable>
                    </Container>
                </div>
            </div>
            <!-- e: item_list_sec-->

            <!-- s: preview_sec -->
            <div class="preview_sec">
                <div class="preview_slide_time_area">
                    <!-- Dev : 시간 미입력시 is_error 클래스 추가          -->
                    <div class="input_area inline"
                         :class="!isNaN(vmdPlayTime[activeIndex]) && vmdPlayTime[activeIndex] && vmdPlayTime[activeIndex] > 0 ? '' : 'is_error'">
                        <label>이미지 슬라이드 시간</label>
                        <div class="input_box">
                            <!-- Dev : default 값 5초 -->
                            <input type="number" :disabled="!vmdEditMode[activeIndex]"
                                   v-model="vmdPlayTime[activeIndex]" @change="countPlayTime" @input="countPlayTime">
                        </div>
                        <div class="input_txt">초</div>
                        <!-- Dev : 이미지가 총 재생되는 재생시간 -->
                        <div class="play_time">(총 재생 시간 :
                            {{ totalPlayTime[activeIndex] ? totalPlayTime[activeIndex].toFixed(2) : '0' }}초)
                        </div>
                        <!-- Dev: 에러시 출력 -->
                        <small class="error_txt"
                               v-if="isNaN(vmdPlayTime[activeIndex]) || !vmdPlayTime[activeIndex] || vmdPlayTime[activeIndex] < 1">슬라이드
                            시간을
                            입력 해 주세요.</small>
                    </div>
                </div>
                <!-- Dev : 우리매장 핫딜, 반값초이스 일시 preview_wrap -->
                <div class="preview_wrap basic"
                     v-if="Number(channel.templateType) === Number(templateTypes.HOT_DEAL.code) || Number(channel.templateType) === Number(templateTypes.HALF_PRICE.code)">
                    <div class="upload_guide" role="button">
                        <div class="tooltip_area">
                            <img src="../../../assets/img/common/tooltip.png" alt="">
                            <p>파일 업로드 가이드</p>
                        </div>
                        <div class="layer_info">
                            <div class="info_area" v-for="(item, i) in tooltip" :key="'item' + i">
                                <div class="ttl">{{ item.ttl }}</div>
                                <div class="cnt">
                                    <p>{{ item.file }}</p>
                                    <p>{{ item.memory }}</p>
                                    <p>{{ item.display }}</p>
                                </div>
                            </div>
                          <p class="info_txt">※ 파일명은 영어로 등록해주세요.</p>
                        </div>
                    </div>
                    <div class="preview_area edit" v-if="vmdEditMode[activeIndex]">
                        <div class="swiper_nav_prev"></div>
                        <div class="swiper_nav_next"></div>
                        <div class="slide_area">
                            <div class="swiper_pagination"></div>
                            <swiper @swiper="setControlledSwiper"
                                    v-bind="swiperOptions"
                                    slidesPerView="auto"
                                    :spaceBetween="10"
                                    :loop="false"
                                    :pagination=" { el: '.swiper_pagination', }"
                                    :navigation=" { nextEl: '.swiper_nav_next', prevEl: '.swiper_nav_prev' }"
                            >
                                <swiper-slide v-if="vmdEditMode[activeIndex]">
                                    <!-- Dev : 등록된 파일이 없는 경우 -->
                                    <template v-if="true">
                                        <div class="upload_btn_wrap">
                                            <div class="upload_btn" role="button">
                                                <div class="txt_area">
                                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                </div>
                                                <div class="btn_area">
                                                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                                    <input type="file"
                                                           accept="image/gif,image/jpeg,image/png,video/mp4"
                                                           @change="onFileChange($event)">
                                                </div>
                                                <div class="info_area">
                                                    <p>
                                                        png/jpg, 1000*1000<br>
                                                        mp4, 1920*1080
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </swiper-slide>
                                <swiper-slide v-if="clickedVmd" v-for="(file, index) in clickedVmd.files">
                                    <!--Dev: 파일이 이미지일 경우-->
                                    <template v-if="isImage(file)">
                                        <div class="model_img_wrap">
                                            <div class="model_img_box"
                                                 :style="`background-image: url('${getFile(file)}')`">
                                            </div>
                                            <div class="hover_btn_area" v-if="vmdEditMode[activeIndex]">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept="image/gif,image/jpeg,image/png,video/mp4"
                                                           @change="onFileChange($event, index)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn" @click="deleteImage(index)">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                    <!--Dev: 파일이 영상일 경우 :: 수정중-->
                                    <template v-if="isVideo(file)">
                                        <div class="upload_video">
<!--                                            <p>index : {{index}} | file : {{file}}</p>-->
                                            <video :ref="`video-${file}`" class="video_box">
                                                <source :src="getFile(file)">
                                            </video>
                                            <button class="video_play_btn play" @click="playVideo('play', file, index)"
                                                    v-if="playStatus[index] === false || !playStatus[index]">
                                                <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                            </button>
                                            <button class="video_play_btn pause"
                                                    @click="playVideo('pause', file, index)"
                                                    v-if="playStatus[index] === true">
                                                <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                                            </button>
                                            <div class="hover_btn_area" v-if="vmdEditMode[activeIndex]">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept=".mp4"
                                                           @change="onFileChange($event, index)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn" @click="deleteImage(index)">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <!-- Dev : 제품정보가 등록 되었을때 -->
                        <div v-if="!vmdEditMode[activeIndex] && clickedVmd" class="product_wrap">
                            <div class="product_name_area">
                                <h3>{{clickedVmd.name}}</h3>
                            </div>
                            <div class="product_sub_name_area">
                                <p>{{clickedVmd.subName}}</p>
                            </div>
                            <div class="product_info_box">
                                <!--   Dev : 핫딜 VMD 일때 -->
                                <dl class="info_hotdeal"
                                    v-if="Number(clickedVmd.templateType) === Number(templateTypes.HOT_DEAL.code)">
                                    <div class="retail_price">
                                        <dt>소비자가</dt>
                                        <dd>
                                            <strong>{{printAsPrice(clickedVmd.retailPrice)}}</strong>원
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>할인가</dt>
                                        <dd class="strong">
                                            <span>인터넷/TV 가입 시</span>
                                            <strong>{{printAsPrice(clickedVmd.discountedPrice)}}</strong>원
                                        </dd>
                                    </div>
                                    <div class="membership_price">
                                        <dt>멤버십 할인가</dt>
                                        <dd class="strong">
                                            <strong>{{printAsPrice(clickedVmd.membershipPrice)}}</strong>원
                                        </dd>
                                    </div>
                                </dl>
                                <!--   Dev : 반값초이스 일때 -->
                                <dl class="info_halfprice"
                                    v-if="Number(clickedVmd.templateType) === Number(templateTypes.HALF_PRICE.code)">
                                    <div class="normal">
                                        <dt>정상가</dt>
                                        <dd><strong>{{printAsPrice(clickedVmd.normalPrice)}}</strong>원</dd>
                                    </div>
                                    <div>
                                        <dt>일시납</dt>
                                        <dd class="strong">
                                            <span>인터넷/TV 가입 시</span>
                                            <strong>{{printAsPrice(clickedVmd.temporaryPrice)}}</strong>원
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>할부납({{clickedVmd.installmentMonth}}개월)</dt>
                                        <dd class="strong">월
                                            <strong>{{printAsPrice(clickedVmd.installmentPrice)}}</strong>원
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div class="features_box" v-if="getDesc.length > 0">
                                <div class="ttl">
                                    <p>제품 설명</p>
                                </div>
                                <div class="cnt">
                                    <ul>
                                        <li v-for="desc in getDesc">{{desc}}</li>
                                        <!--                                <li>화면을 가득 채우는 16:10 화면 디스플레이</li>
                                                                        <li>더 부드럽고 매끄러워진 화연 움직임</li>
                                                                        <li>세련된 컬러와 아노다이징 디자인, 견고한 바디</li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!-- Dev : 제품정보가 등록되지 않았을때 -->
                        <div v-else class="product_wrap_none">
                            <div class="product_name_area">
                                <!--  Dev : error시 is_error 추가 -->
                                <div class="input_area inline" :class="{is_error : !clickedVmd.name}">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="text" placeholder="제목" v-model="clickedVmd.name">
                                    </div>
                                    <small class="error_txt" v-if="!clickedVmd.name">제목을 입력해주세요.</small>
                                </div>
                            </div>
                            <div class="product_sub_name_area">
                                <div class="input_area inline">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="text" placeholder="부제목" v-model="clickedVmd.subName">
                                    </div>
                                </div>
                            </div>
                            <div class="product_info_box">
                                <!--   Dev : 핫딜 VMD 일때 -->
                                <dl class="info_hotdeal"
                                    v-if="Number(channel.templateType) === Number(templateTypes.HOT_DEAL.code)">
                                    <div class="retail_price">
                                        <dt class="must_txt">소비자가</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline"
                                                 :class="{is_error : !clickedVmd.retailPrice}">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.retailPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small v-if="!clickedVmd.retailPrice" class="error_txt">소비자가를
                                                    입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>할인가</dt>
                                        <dd>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.discountedPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                            </div>
                                        </dd>
                                    </div>
                                    <div class="membership_price">
                                        <dt class="must_txt">멤버십 할인가</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline"
                                                 :class="{is_error : !clickedVmd.membershipPrice}">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.membershipPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small v-if="!clickedVmd.membershipPrice" class="error_txt">맴버십 할인가를
                                                    입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                                <!--   Dev : 반값초이스 일때 -->
                                <dl class="info_halfprice"
                                    v-if="Number(channel.templateType) === Number(templateTypes.HALF_PRICE.code)">
                                    <div class="normal">
                                        <dt class="must_txt">정상가</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline"
                                                 :class="{is_error : !clickedVmd.normalPrice}">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.normalPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small v-if="!clickedVmd.normalPrice" class="error_txt">정상가를
                                                    입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt class="must_txt">일시납</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline"
                                                 :class="{is_error : !clickedVmd.temporaryPrice}">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.temporaryPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small v-if="!clickedVmd.temporaryPrice" class="error_txt">일시납을
                                                    입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>할부납</dt>
                                        <dd>
                                            <div class="input_area select inline">
                                                <select v-model.number="clickedVmd.installmentMonth">
                                                    <option :value="12">12개월</option>
                                                    <option :value="24">24개월</option>
                                                    <option :value="36">36개월</option>
                                                </select>
                                            </div>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.installmentPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div class="features_box">
                                <div class="ttl">
                                    <p>제품 설명</p>
                                </div>
                                <div class="cnt">
                                    <ul>
                                        <li>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="설명1" v-model="clickedVmd.desc[0]">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="설명2" v-model="clickedVmd.desc[1]">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="설명3" v-model="clickedVmd.desc[2]">
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="preview_area preview" v-else>
                        <div class="swiper_nav_prev"></div>
                        <div class="swiper_nav_next"></div>
                        <div class="slide_area">
                            <div class="swiper_pagination"></div>
                            <swiper @swiper="setControlledSwiper"
                                    v-bind="swiperOptions"
                                    slidesPerView="auto"
                                    :spaceBetween="10"
                                    :loop="false"
                                    :pagination=" { el: '.swiper_pagination', }"
                                    :navigation=" { nextEl: '.swiper_nav_next', prevEl: '.swiper_nav_prev' }"
                            >
                                <swiper-slide v-if="vmdEditMode[activeIndex]">
                                    <!-- Dev : 등록된 파일이 없는 경우 -->
                                    <template v-if="true">
                                        <div class="upload_btn_wrap">
                                            <div class="upload_btn" role="button">
                                                <div class="txt_area">
                                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                </div>
                                                <div class="btn_area">
                                                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                                    <input type="file"
                                                           accept="image/gif,image/jpeg,image/png,video/mp4"
                                                           @change="onFileChange($event)">
                                                </div>
                                                <div class="info_area">
                                                    <p>
                                                        png/jpg, 1000*1000<br>
                                                        mp4, 1920*1080
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </swiper-slide>
                                <swiper-slide v-if="clickedVmd" v-for="(file, index) in clickedVmd.files">
                                    <!--Dev: 파일이 이미지일 경우-->
                                    <template v-if="isImage(file)">
                                        <div class="model_img_wrap">
                                            <div class="model_img_box"
                                                 :style="`background-image: url('${getFile(file)}')`">
                                            </div>
                                            <div class="hover_btn_area" v-if="vmdEditMode[activeIndex]">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept="image/gif,image/jpeg,image/png,video/mp4"
                                                           @change="onFileChange($event, index)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn" @click="deleteImage(index)">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                    <!--Dev: 파일이 영상일 경우 :: 보기용-->
                                    <template v-if="isVideo(file)">
                                        <div class="upload_video">
<!--                                            <p>index : {{index}} | file : {{file}}</p>-->
                                            <video :ref="`video-${file}`" class="video_box">
                                                <source :src="getFile(file)">
                                            </video>
                                            <button class="video_play_btn play" @click="playVideo('play', file, index)"
                                                    v-if="playStatus[index] === false || !playStatus[index]">
                                                <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                            </button>
                                            <button class="video_play_btn pause"
                                                    @click="playVideo('pause', file, index)"
                                                    v-if="playStatus[index] === true">
                                                <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                                            </button>
                                            <div class="hover_btn_area" v-if="vmdEditMode[activeIndex]">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept=".mp4"
                                                           @change="onFileChange($event, index)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn" @click="deleteImage(index)">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <!-- Dev : 제품정보가 등록 되었을때 -->
                        <div v-if="!vmdEditMode[activeIndex] && clickedVmd" class="product_wrap">
                            <div class="product_name_area">
                                <h3>{{clickedVmd.name}}</h3>
                            </div>
                            <div class="product_sub_name_area">
                                <p>{{clickedVmd.subName}}</p>
                            </div>
                            <div class="product_info_box">
                                <!--   Dev : 핫딜 VMD 일때 -->
                                <dl class="info_hotdeal"
                                    v-if="Number(clickedVmd.templateType) === Number(templateTypes.HOT_DEAL.code)">
                                    <div class="retail_price">
                                        <dt>소비자가</dt>
                                        <dd>
                                            <strong>{{printAsPrice(clickedVmd.retailPrice)}}</strong>원
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>할인가</dt>
                                        <dd class="strong">
                                            <span>인터넷/TV 가입 시</span>
                                            <strong>{{printAsPrice(clickedVmd.discountedPrice)}}</strong>원
                                        </dd>
                                    </div>
                                    <div class="membership_price">
                                        <dt>멤버십 할인가</dt>
                                        <dd class="strong">
                                            <strong>{{printAsPrice(clickedVmd.membershipPrice)}}</strong>원
                                        </dd>
                                    </div>
                                </dl>
                                <!--   Dev : 반값초이스 일때 -->
                                <dl class="info_halfprice"
                                    v-if="Number(clickedVmd.templateType) === Number(templateTypes.HALF_PRICE.code)">
                                    <div class="normal">
                                        <dt>정상가</dt>
                                        <dd><strong>{{printAsPrice(clickedVmd.normalPrice)}}</strong>원</dd>
                                    </div>
                                    <div>
                                        <dt>일시납</dt>
                                        <dd class="strong">
                                            <span>인터넷/TV 가입 시</span>
                                            <strong>{{printAsPrice(clickedVmd.temporaryPrice)}}</strong>원
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>할부납({{clickedVmd.installmentMonth}}개월)</dt>
                                        <dd class="strong">월
                                            <strong>{{printAsPrice(clickedVmd.installmentPrice)}}</strong>원
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div class="features_box" v-if="getDesc.length > 0">
                                <div class="ttl">
                                    <p>제품 설명</p>
                                </div>
                                <div class="cnt">
                                    <ul>
                                        <li v-for="desc in getDesc">{{desc}}</li>
                                        <!--                                <li>화면을 가득 채우는 16:10 화면 디스플레이</li>
                                                                        <li>더 부드럽고 매끄러워진 화연 움직임</li>
                                                                        <li>세련된 컬러와 아노다이징 디자인, 견고한 바디</li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!-- Dev : 제품정보가 등록되지 않았을때 -->
                        <div v-else class="product_wrap_none">
                            <div class="product_name_area">
                                <!--  Dev : error시 is_error 추가 -->
                                <div class="input_area inline" :class="{is_error : !clickedVmd.name}">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="text" placeholder="제목" v-model="clickedVmd.name">
                                    </div>
                                    <small class="error_txt" v-if="!clickedVmd.name">제목을 입력해주세요.</small>
                                </div>
                            </div>
                            <div class="product_sub_name_area">
                                <div class="input_area inline">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="text" placeholder="부제목" v-model="clickedVmd.subName">
                                    </div>
                                </div>
                            </div>
                            <div class="product_info_box">
                                <!--   Dev : 핫딜 VMD 일때 -->
                                <dl class="info_hotdeal"
                                    v-if="Number(channel.templateType) === Number(templateTypes.HOT_DEAL.code)">
                                    <div class="retail_price">
                                        <dt class="must_txt">소비자가</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline"
                                                 :class="{is_error : !clickedVmd.retailPrice}">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.retailPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small v-if="!clickedVmd.retailPrice" class="error_txt">소비자가를
                                                    입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>할인가</dt>
                                        <dd>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.discountedPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                            </div>
                                        </dd>
                                    </div>
                                    <div class="membership_price">
                                        <dt class="must_txt">멤버십 할인가</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline"
                                                 :class="{is_error : !clickedVmd.membershipPrice}">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.membershipPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small v-if="!clickedVmd.membershipPrice" class="error_txt">맴버십 할인가를
                                                    입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                                <!--   Dev : 반값초이스 일때 -->
                                <dl class="info_halfprice"
                                    v-if="Number(channel.templateType) === Number(templateTypes.HALF_PRICE.code)">
                                    <div class="normal">
                                        <dt class="must_txt">정상가</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline"
                                                 :class="{is_error : !clickedVmd.normalPrice}">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.normalPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small v-if="!clickedVmd.normalPrice" class="error_txt">정상가를
                                                    입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt class="must_txt">일시납</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline"
                                                 :class="{is_error : !clickedVmd.temporaryPrice}">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.temporaryPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small v-if="!clickedVmd.temporaryPrice" class="error_txt">일시납을
                                                    입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>할부납</dt>
                                        <dd>
                                            <div class="input_area select inline">
                                                <select v-model.number="clickedVmd.installmentMonth">
                                                    <option :value="12">12개월</option>
                                                    <option :value="24">24개월</option>
                                                    <option :value="36">36개월</option>
                                                </select>
                                            </div>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" v-model.number="clickedVmd.installmentPrice">
                                                </div>
                                                <div class="input_txt">원</div>
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div class="features_box">
                                <div class="ttl">
                                    <p>제품 설명</p>
                                </div>
                                <div class="cnt">
                                    <ul>
                                        <li>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="설명1" v-model="clickedVmd.desc[0]">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="설명2" v-model="clickedVmd.desc[1]">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="설명3" v-model="clickedVmd.desc[2]">
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Dev : 만능체험대 일시 preview_wrap -->
                <div class="preview_wrap experience"
                     v-if="Number(channel.templateType) === Number(templateTypes.EXPLORE.code)">
                    <div class="upload_guide" role="button">
                        <div class="tooltip_area">
                            <img src="../../../assets/img/common/tooltip.png" alt="">
                            <p>파일 업로드 가이드</p>
                        </div>
                        <div class="layer_info">
                            <div class="info_area" v-for="(item, i) in tooltip" :key="'item' + i">
                                <div class="ttl">{{ item.ttl }}</div>
                                <div class="cnt">
                                    <p>{{ item.file }}</p>
                                    <p>{{ item.memory }}</p>
                                    <p>{{ item.experience }}</p>
                                </div>
                            </div>
                          <p class="info_txt">※ 파일명은 영어로 등록해주세요.</p>
                        </div>
                    </div>
                    <div class="experience_wrap">
                        <div class="preview_area edit">
                            <div class="swiper_nav_prev"></div>
                            <div class="swiper_nav_next"></div>
                            <div class="slide_area">
                                <template v-if="!vmdEditMode[activeIndex]">
                                    <swiper @swiper="setControlledSwiper"
                                            slidesPerView="auto"
                                            :loop="false"
                                            :navigation=" { nextEl: '.swiper_nav_next', prevEl: '.swiper_nav_prev' }"
                                    >
                                        <swiper-slide v-if="vmdEditMode[activeIndex]">
                                            <!-- Dev : 등록된 파일이 없는 경우 -->
                                            <template v-if="true">
                                                <div class="upload_btn_wrap">
                                                    <div class="upload_btn" role="button">
                                                        <div class="txt_area">
                                                            <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                        </div>
                                                        <div class="btn_area">
                                                            <button type="button" class="btn basic w_smm h_m">파일 추가
                                                            </button>
                                                            <input type="file"
                                                                   accept="image/gif,image/jpeg,image/png,video/mp4"
                                                                   @change="onFileChange($event)">
                                                        </div>
                                                        <div class="info_area">
                                                            <p>
                                                                png/jpg, 1920*1080<br>
                                                                mp4, 1920*1080
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </swiper-slide>
                                        <swiper-slide v-for="(file, index) in clickedVmd.files">
                                            <!--Dev: 파일이 이미지일 경우-->
                                            <template v-if="isImage(file)">
                                                <div class="model_img_wrap">
                                                    <div class="model_img_box"
                                                         :style="`background-image: url('${getFile(file)}')`">
                                                    </div>
                                                    <div class="hover_btn_area" v-if="vmdEditMode[activeIndex]">
                                                        <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                        <button class="modify_btn">
                                                            <img src="../../../assets/img/common/video_modify.png"
                                                                 alt="">
                                                            <input type="file"
                                                                   accept="image/gif,image/jpeg,image/png,video/mp4"
                                                                   @change="onFileChange($event, index)">
                                                        </button>
                                                        <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                        <button class="delete_btn" @click="deleteImage(index)">삭제
                                                        </button>
                                                    </div>
                                                </div>
                                            </template>
                                            <!--Dev: 파일이 영상일 경우-->
                                            <template v-if="isVideo(file)">
                                                <div class="upload_video">
                                                    <video :ref="`video-${file}`" class="video_box">
                                                        <source :src="getFile(file)">
                                                    </video>
                                                    <button class="video_play_btn play"
                                                            @click="playVideo('play', file, index)"
                                                            v-if="playStatus[index] === false || !playStatus[index]">
                                                        <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                                    </button>
                                                    <button class="video_play_btn pause"
                                                            @click="playVideo('pause', file, index)"
                                                            v-if="playStatus[index] === true">
                                                        <img src="../../../assets/img/common/video_pause_btn.png"
                                                             alt="">
                                                    </button>
                                                    <div class="hover_btn_area" v-if="vmdEditMode[activeIndex]">
                                                        <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                        <button class="modify_btn">
                                                            <img src="../../../assets/img/common/video_modify.png"
                                                                 alt="">
                                                            <input type="file" accept=".mp4"
                                                                   @change="onFileChange($event, index)">
                                                        </button>
                                                        <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                        <button class="delete_btn" @click="deleteImage(index)">삭제
                                                        </button>
                                                    </div>
                                                </div>
                                            </template>
                                        </swiper-slide>
                                    </swiper>
                                </template>
                                <template v-else>
                                    <swiper @swiper="setControlledSwiper"
                                            slidesPerView="1.2"
                                            :loop="false"
                                            :navigation=" { nextEl: '.swiper_nav_next', prevEl: '.swiper_nav_prev' }"
                                    >
                                        <swiper-slide v-if="vmdEditMode[activeIndex]">
                                            <!-- Dev : 등록된 파일이 없는 경우 -->
                                            <template v-if="true">
                                                <div class="upload_btn_wrap">
                                                    <div class="upload_btn" role="button">
                                                        <div class="txt_area">
                                                            <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                        </div>
                                                        <div class="btn_area">
                                                            <button type="button" class="btn basic w_smm h_m">파일 추가
                                                            </button>
                                                            <input type="file"
                                                                   accept="image/gif,image/jpeg,image/png,video/mp4"
                                                                   @change="onFileChange($event)">
                                                        </div>
                                                        <div class="info_area">
                                                            <p>
                                                                png/jpg, 1920*1080<br>
                                                                mp4, 1920*1080
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </swiper-slide>
                                        <swiper-slide v-for="(file, index) in clickedVmd.files">
                                            <!--Dev: 파일이 이미지일 경우-->
                                            <template v-if="isImage(file)">
                                                <div class="model_img_wrap">
                                                    <div class="model_img_box"
                                                         :style="`background-image: url('${getFile(file)}')`">
                                                    </div>
                                                    <div class="hover_btn_area">
                                                        <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                        <button class="modify_btn">
                                                            <img src="../../../assets/img/common/video_modify.png"
                                                                 alt="">
                                                            <input type="file"
                                                                   accept="image/gif,image/jpeg,image/png,video/mp4"
                                                                   @change="onFileChange($event, index)">
                                                        </button>
                                                        <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                        <button class="delete_btn" @click="deleteImage(index)">삭제
                                                        </button>
                                                    </div>
                                                </div>
                                            </template>
                                            <!--Dev: 파일이 영상일 경우-->
                                            <template v-if="isVideo(file)">
                                                <div class="upload_video">
                                                    <video :ref="`video-${file}`" class="video_box">
                                                        <source :src="getFile(file)">
                                                    </video>
                                                    <button class="video_play_btn play"
                                                            @click="playVideo('play', file, index)"
                                                            v-if="playStatus[index] === false || !playStatus[index]">
                                                        <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                                    </button>
                                                    <button class="video_play_btn pause"
                                                            @click="playVideo('pause', file, index)"
                                                            v-if="playStatus[index] === true">
                                                        <img src="../../../assets/img/common/video_pause_btn.png"
                                                             alt="">
                                                    </button>
                                                    <div class="hover_btn_area">
                                                        <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                        <button class="modify_btn">
                                                            <img src="../../../assets/img/common/video_modify.png"
                                                                 alt="">
                                                            <input type="file" accept=".mp4"
                                                                   @change="onFileChange($event, index)">
                                                        </button>
                                                        <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                        <button class="delete_btn" @click="deleteImage(index)">삭제
                                                        </button>
                                                    </div>
                                                </div>
                                            </template>
                                        </swiper-slide>
                                    </swiper>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- e: preview_sec -->
        </div>
        <!-- e : content_body    -->

        <!-- s: content_foot -->
        <div class="content_foot">
            <div class="btn_group center">
                <!-- Dev : 첨부파일에 대한 부분만 리셋        -->
                <a v-if="!vmdEditMode[activeIndex]" @click="redirect('/channel')" href="javascript:void(0)"
                   class="btn basic w_m h_l">목록</a>
                <!-- Dev : 클릭 시 메인 영상 등록: 파일 첨부 반영  -->
                <button class="btn basic w_m h_l" v-if="vmdEditMode[activeIndex] && activeIndex === -1"
                        @click="clickVmd(0, orderedVmd[0])">취소
                </button>
                <button class="btn basic w_m h_l" v-if="vmdEditMode[activeIndex] && activeIndex !== -1"
                        @click="vmdEditMode[activeIndex] = false">취소
                </button>
                <button v-if="!vmdEditMode[activeIndex]" class="btn basic w_m h_l"
                        @click="vmdEditMode[activeIndex] = true">수정
                </button>
                <button v-else class="btn strong w_m h_l" @click="registVmd()">저장</button>
            </div>
        </div>
        <!-- e : content_foot  -->
    </div>
    <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
            @yesDialog="deleteVmd()">
        <template #body>
            <h3>삭제 확인</h3>
            <p>해당 VMD를 삭제하시겠습니까?<br>
                변경된 내용은 채널에서 “발행하기”를<br>
                진행해야 Tablet에 반영됩니다.
            </p>
        </template>
    </Dialog>
</template>

<script>
import {Container, Draggable} from "vue3-smooth-dnd";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper/core';
import moment from "moment";
import getBlobDuration from "get-blob-duration";
import Dialog from "@/components/dialog";
import {TEMPLATE} from "@/plugins/firebase/type";
import Channel from "@/plugins/firebase/model/channel";
import VMD from "@/plugins/firebase/model/vmd";
import HotDeal from "@/plugins/firebase/model/vmd/hotdeal";
import HalfPrice from "@/plugins/firebase/model/vmd/halfprice";
import Explore from "@/plugins/firebase/model/vmd/explore";
import {applyDrag} from "@/plugins/dnd-helpers";
import {fileNameFromUrl} from "@/plugins/network";

SwiperCore.use([Autoplay, Navigation, Pagination]);

export default {
    name: "new-index",
    layout: 'new-admin',
    components: {
        Container: Container,
        Draggable: Draggable,
        Swiper,
        SwiperSlide,
        Dialog,
    },
    computed: {
        orderedVmd() {
            return this.channel.vmd?.sort((a, b) => {
                return a.order - b.order;
            });
        },
        getDesc() {
            if (this.clickedVmd && this.clickedVmd.desc) {
                return this.clickedVmd.desc.filter((desc) => {
                    if (desc && desc.length > 0) return true;
                });
            }
            return [];
        }
    },
    data() {
        return {
            swiperOptions: {},
            dropPlaceholderOptions: {
                className: 'drop-preview',
                animationDuration: '150',
                showOnTop: true
            },
            controlledSwiper: null,
            validation: {
                name: true,
                normalPrice: true,
                specialPrice: true,
                temporaryPrice: true,
                retailPrice: true,
                discountedPrice: true,
                membershipPrice: true
            },
            deleteDialogState: false,
            registrationDialogState: false,
            tooltip: [
                {
                    ttl: '이미지 파일형식',
                    file: 'JPG, PNG',
                    memory: '용량 2MB 이하',
                    display: '해상도 1000*1000',
                    experience: '해상도 1920*1080',
                },
                {
                    ttl: '영상 파일형식',
                    file: 'MP4',
                    memory: '용량 80MB 이하',
                    display: '해상도 1920*1080',
                    experience: '해상도 1920*1080',
                },
            ],

            pageLoaded: false,
            channelHash: null,
            templateTypes: TEMPLATE,
            templateCode: null,
            templateName: '',
            channel_root_key: 'channel',
            channel: null,
            originalChannel: {},
            editChannelName: '',
            titleEditMode: false,
            vmdCreateFormShow: false,
            vmdPlayTime: [],
            totalPlayTime: [],
            activeIndex: -1,
            clickedVmd: null,
            vmdEditMode: [],
            playStatus: [],
            removeIndex: -1,
            isUploading: false,
        }
    },
    methods: {
        fileNameFromUrl: fileNameFromUrl,
        onFileChange(e, index) {
            if (typeof index === 'undefined' && this.clickedVmd.files.length >= 3) {
                this.toastDanger('VMD당 이미지는 최대 3개까지 입니다.');
                return;
            }
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            let file = files[0];

            const isImage = this.isImage(file);
            const isVideo = this.isVideo(file);
            const maxImageSize = 2 * 1024 * 1024;
            const maxVideoSize = 80 * 1024 * 1024;
            if (!isImage && !isVideo) {
                this.toastDanger('이미지는 .jpg, .png, 동영상은 .mp4만 지원합니다.');
                return;
            }
            if (isImage) {
                if (maxImageSize < file.size) {
                    this.toastDanger('이미지 용량은 2MB 미만으로 등록해주세요.');
                    return;
                }
            } else if (isVideo) {
                if (maxVideoSize < file.size) {
                    this.toastDanger('동영상 용량은 30MB 미만으로 등록해주세요.');
                    return;
                }
            }
            if (isNaN(Number(index))) {
                this.clickedVmd.files.push(file);
            } else {
                if (this.isVideo(file)) {
                    let video = this.$refs[`video-${file}`];
                    if (!video) video = this.$refs[`video-${this.clickedVmd.files[index]}`];
                    if (video) {
                        const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
                        if (isVideoPlaying) {
                            try {
                                video.pause();
                            } catch (e) {
                            }
                        }
                        video.load();
                    } else {

                    }
                    this.playStatus[index] = false;
                }
                this.clickedVmd.files[index] = file;
            }
            this.countPlayTime();
            //this.countPlayTime();
        },
        deleteImage(index) {
            this.clickedVmd.files.splice(index, 1);
            this.$nextTick(() => {
                this.clickedVmd.files.forEach((file) => {
                    if (this.isVideo(file)) {
                        let video = this.$refs[`video-${file}`];
                        if (!video) video = this.$refs[`video-${this.clickedVmd.files[index]}`];
                        if (video) {
                            const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
                            if (isVideoPlaying) {
                                try {
                                    video.pause();
                                } catch (e) {
                                }
                            }
                            video.load();
                        } else {

                        }
                        this.playStatus[index] = false;
                    }
                });
            });
        },
        countPlayTime() {
            this.totalPlayTime[this.activeIndex] = 0;
            if (this.clickedVmd && this.clickedVmd.files) {
                for (const file of this.clickedVmd.files) {
                    const isVideo = this.isVideo(file);
                    const isImage = this.isImage(file);
                    if (isVideo) {
                        try {
                            const blob = URL.createObjectURL(file);
                            getBlobDuration(blob).then((i) => {
                                this.totalPlayTime[this.activeIndex] += i;
                            });
                        } catch (ignore) {
                            fetch(file).then(res => res.blob()).then(blob => {
                                getBlobDuration(blob).then((i) => {
                                    this.totalPlayTime[this.activeIndex] += i;
                                });
                            });
                        }
                    } else if (isImage) {
                        this.totalPlayTime[this.activeIndex] += Number(this.vmdPlayTime[this.activeIndex]);
                    } else {
                        if (file) {
                            //console.log(file);
                            const url = file.toString().toLowerCase();
                            if (url.indexOf('.mp4')) {
                                try {
                                    const blob = URL.createObjectURL(file);
                                    getBlobDuration(blob).then((i) => {
                                        this.totalPlayTime[this.activeIndex] += i;
                                    });
                                } catch (ignore) {
                                    fetch(file).then(res => res.blob()).then(blob => {
                                        getBlobDuration(blob).then((i) => {
                                            this.totalPlayTime[this.activeIndex] += i;
                                        });
                                    });
                                }

                            } else {
                                this.totalPlayTime[this.activeIndex] += Number(this.vmdPlayTime[this.activeIndex]);
                            }
                        }
                    }
                }
            }
        },
        onDrop(dropResult) {
            if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
                this.channel.vmd[dropResult.removedIndex].order = dropResult.addedIndex + 1;
                this.channel.vmd[dropResult.addedIndex].order = dropResult.removedIndex + 1;
            }

            this.channel.vmd = applyDrag(this.channel.vmd, dropResult);
            this.channel.vmd?.forEach((item, index) => {
                item.order = index + 1;
            });
            this.channel.vmd = this.channel.vmd.sort((a, b) => {
                return a.order - b.order;
            });
            this.channel.lastModifiedDateTime = this.timeToString(moment());
            this.$firebase.database().ref(this.channel_root_key).child(this.channelHash).set(this.channel);
            this.activeIndex = dropResult.addedIndex;
            this.clickedVmd = this.channel.vmd[this.activeIndex];
        },
        setControlledSwiper(swiper) {
            this.controlledSwiper = swiper;
        },
        playVideo(status, file, index) {
            const video = this.$refs[`video-${file}`];
            if (status === 'play') {
                video.play();
                this.playStatus[index] = true;
            } else {
                video.pause();
                this.playStatus[index] = false;
            }
        },
        async deleteVmd() {
            this.channel.lastModifiedDateTime = this.timeToString(moment());
            this.channel.vmd.splice(this.removeIndex, 1);
            await this.$firebase.database().ref(this.channel_root_key).child(this.channelHash).set(this.channel).then(() => {
                if (this.channel.vmd && this.channel.vmd.length > 0) {
                    this.clickVmd(0, this.channel.vmd[0]);
                } else {
                    this.clickAddBtn();
                }
                this.removeIndex = -1;
                this.deleteDialogState = false;
                this.toastSuccess('VMD가 삭제 되었습니다.');
            });

            // this.redirect('/vmd');
        },
        removeList(index) {
            this.removeIndex = index;
            this.deleteDialogState = true;
        },
        async registVmd() {
            if (this.isUploading) {
                return;
            }
            if (!this.channel.name) {
                this.toastDanger('채널명을 입력 해 주세요.');
                return;
            }
            const validation = this.clickedVmd.validate();
            if (validation && Array.isArray(validation) && validation.length > 0) {
                validation.forEach((val) => {
                    this.validator(val.key, val.scheme.type);
                });
                this.toastDanger(validation.map((val) => {
                    return val.scheme.msg
                }).join('<br>'));
                return;
            }

            if (!this.anyFileExists()) {
                this.toastDanger('최소 하나의 파일이 등록되어야 합니다');
                return;
            }
            //업로드 시작
            this.isUploading = true;
            this.loadingOverlay(async (loader) => {
                if (this.activeIndex === -1) {
                    if (!this.channel.vmd) {
                        this.channel.vmd = [];
                    }
                    this.channel.vmd.push(this.clickedVmd);
                } else {
                    this.channel.vmd[this.activeIndex] = this.clickedVmd;
                }
                if (!this.channelHash) {
                    this.channelHash = this.$firebase.database().ref(this.channel_root_key).push(this.channel).key;
                }
                this.channel.lastModifiedDateTime = this.timeToString(moment());
                let order = 2;
                for (let [index, vmd] of Object.entries(this.channel.vmd)) {
                    if (vmd.files && vmd.files.length > 0) {
                        vmd.templateType = this.channel.templateType;
                        vmd.creationDateTime = this.timeToString(moment());
                        vmd.lastModifiedDateTime = this.timeToString(moment());
                        vmd.imagePlayTime = this.vmdPlayTime[index] ? this.vmdPlayTime[index] : this.vmdPlayTime[-1];
                        if (vmd.order !== 0) {
                            vmd.order = order++;
                        } else {
                            vmd.order = 1;
                        }
                        let availables = vmd.files.filter(x => x && typeof x !== 'undefined');
                        if (availables.length > 0) {
                            this.$Progress.start();
                            for await (const file of availables) {
                                if (file && file instanceof File) {
                                    const index = availables.indexOf(file);
                                    let downloadUrl = await this.upload(this.channelHash, file, index, availables.length);
                                    vmd.files[index] = downloadUrl;
                                    vmd.filesPlayTime += await this.getFileDuration(file);
                                }
                            }
                            if (vmd.filesPlayTime) {
                                vmd.filesPlayTime = Number(vmd.filesPlayTime.toFixed(2));
                            }
                            this.$Progress.finish();
                            vmd.lastModifiedDateTime = this.timeToString(moment());
                        }
                    }
                }

                await this.$firebase.database().ref(this.channel_root_key).child(this.channelHash).set(this.channel).then(() => {
                    this.toastSuccess('VMD가 등록되었습니다.');
                    if (this.activeIndex === -1) {
                        this.activeIndex = 0;
                        this.clickedVmd = this.channel.vmd[this.activeIndex];
                    }
                    this.vmdEditMode[this.activeIndex] = false;
                    this.isUploading = false;
                    //this.redirect('/channel');
                });
                loader.hide();
            });
        },
        async getFileDuration(file) {
            const isVideo = this.isVideo(file);
            const isImage = this.isImage(file);
            if (isVideo) {
                const blob = URL.createObjectURL(file);
                return await getBlobDuration(blob);
            } else if (isImage) {
                return new VMD().imagePlayTime;
            } else {
                if (file) {
                    //console.log(file);
                    const url = file.toString().toLowerCase();
                    if (url.indexOf('.mp4')) {
                        const blob = URL.createObjectURL(file);
                        return await getBlobDuration(blob);
                    } else {
                        return new VMD().imagePlayTime;
                    }
                }
            }
            return 0;
        },
        async upload(hash, image, current, total) {
            let storageRef = this.$firebase.storage().ref();
            let imageRef = storageRef.child(hash).child(image.name);
            let uploadTask = imageRef.put(image);
            return new Promise(((resolve, reject) => {
                uploadTask.on('state_changed', (snapshot) => {
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const gage = (Math.floor(100 / total) * current) + (Math.floor(progress) / total);
                    this.$Progress.set(gage);
                    //console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
                            //console.log('Upload is paused');
                            break;
                        case this.$firebase.storage.TaskState.RUNNING: // or 'running'
                            //console.log('Upload is running');
                            break;
                    }
                }, (error) => {
                    console.error('[ERROR] state_changed', error);
                    reject(error);
                }, () => {
                    // 완료 다운로드 예시
                    resolve(uploadTask.snapshot.ref.getDownloadURL());
                });
            }));
        },
        validator(key, type) {
            if (type === 'string') {
                this.validation[key] = this.clickedVmd[key].length > 0;
            } else if (type === 'positiveNumber') {
                this.validation[key] = this.isNumeric(this.clickedVmd[key]) && Number(this.clickedVmd[key]) >= 0;
            }
            return this.validation[key] ? this.validation[key] : false;
        },
        anyFileExists() {
            return this.clickedVmd.files.map((file) => {
                return this.getFile(file) != null;
            }).some((check) => check === true);
        },
        clickAddBtn() {
            this.activeIndex = -1;
            this.clickedVmd = new VMD();
            this.vmdEditMode[-1] = true;
        },
        clickVmd(index, vmd) {
            if (this.channel && this.channel.vmd) {
                if (index && this.originalChannel?.vmd.hasOwnProperty(index)) {
                    const original = this.originalChannel?.vmd[index];
                    const find = this.originalChannel?.vmd.findIndex((item, i) => i === index);
                    if (find > -1) {
                        this.channel.vmd[find] = JSON.parse(JSON.stringify(original));
                    }
                }
            }

            this.activeIndex = index;
            //this.clickedVmd = vmd;
            switch (this.channel.templateType) {
                case this.templateTypes.HOT_DEAL.code:
                    this.clickedVmd = new HotDeal(vmd);
                    break;
                case this.templateTypes.HALF_PRICE.code:
                    this.clickedVmd = new HalfPrice(vmd);
                    break;
                case this.templateTypes.EXPLORE.code:
                    this.clickedVmd = new Explore(vmd);
                    break;
                default:
                    break;
            }
            if (this.clickedVmd.files && this.clickedVmd.files.length > 0) {
                const hasVideo = this.clickedVmd.files.find(file => this.isVideo(file));
                if (hasVideo) {
                    let video = this.$refs[`video-${hasVideo}`] || document.querySelector('.video_box');
                    if (video) {
                        this.playStatus[index] = false;
                        video.pause();
                        video.load();
                    }

                }
            }
            //console.log(this.clickedVmd.templateType, this.templateTypes.HOT_DEAL.code, this.clickedVmd.templateType === this.templateTypes.HOT_DEAL.code);
            this.vmdEditMode[-1] = false;
            this.countPlayTime();
        },
        reloadData() {
            this.vmdPlayTime[-1] = 5;
            this.totalPlayTime[-1] = 0;
            if (this.channel.vmd) {
                for (const [i, vmd] of this.channel.vmd?.entries()) {
                    this.vmdPlayTime[i] = Number(vmd.imagePlayTime);
                    this.totalPlayTime[i] = Number(0);
                }
            }
            this.countPlayTime();
            this.editChannelName = this.channel.name;
        },
        changeChannelName() {
            this.channel.name = this.editChannelName;
            this.titleEditMode = false;
            this.channel.lastModifiedDateTime = this.timeToString(moment());
            this.$firebase.database().ref(this.channel_root_key).child(this.channelHash).set(this.channel);
        },
    },
    created() {

        this.channelHash = this.$route.params.hash;
        //console.log(this.channelHash);
        // hash없이 수정모드로 들어올 수 없다.
        if (!this.channelHash) {
            this.redirect('/channel');
        }

        this.$firebase.database().ref(this.channel_root_key).child(this.channelHash).once('value', (sn) => {
            this.channel = sn.val() || {};
            this.reloadData();
            this.originalChannel = JSON.parse(JSON.stringify(this.channel));
            if (this.channel.vmd) {
                this.clickedVmd = this.channel.vmd[0];
                this.activeIndex = 0;
                this.clickVmd(0, this.channel.vmd[0]);
            } else {
                this.activeIndex = -1;
                this.clickedVmd = new VMD();
                this.vmdEditMode[-1] = true;
            }

            this.templateCode = Number(this.channel.templateType);
            if (this.templateCode === this.templateTypes.HOT_DEAL.code) {
                this.templateName = this.templateTypes.HOT_DEAL.name;
            } else if (this.templateCode === this.templateTypes.HALF_PRICE.code) {
                this.templateName = this.templateTypes.HALF_PRICE.name;
            } else if (this.templateCode === this.templateTypes.EXPLORE.code) {
                this.templateName = this.templateTypes.EXPLORE.name;
            }
            this.pageLoaded = true;
        })
    },
}
</script>

<style scoped>

</style>